import React, { useState, useContext, Fragment } from "react";
import { IoFlagOutline } from "react-icons/io5";
import { GoPrimitiveDot } from "react-icons/go";
import { RichText } from "../common/RichText/RichText";
import SecurityContext from "../Context/SecurityContext";
import ProposalPostModal from "./ProposalPostModal";
import Tag from "../Tag/Tag";
import _ from "lodash";

import UpvoteContainer from "./UpvoteContainer/UpvoteContainer";

import styles from "./ProposalPost.module.scss";

const ProposalPost = ({ searchQuery, ownBuildingComplexData }) => {
  const { data } = ownBuildingComplexData;

  return (
    <div>
      {data?.user_proposals
        ?.filter(list => {
          if (searchQuery === "") {
            return list;
          } else if (
            list.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            list.created_at.toLowerCase().includes(searchQuery.toLowerCase()) ||
            list.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
            list.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            list.content.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return list;
          }
        })
        .map((list, index) => {
          return <PostItem list={list} index={index} key={index} data={data} />;
        })}
    </div>
  );
};

const PostItem = ({ list, data }) => {
  const [openAnnouncement, setOpenAnnouncement] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [flagData, setFlagData] = useState();
  const { api } = useContext(SecurityContext);
  const { response } = list;

  const handleReportSubmission = (values, { setSubmitting }) => {
    setSubmitting(true);
    const payload = {
      flag: {
        reason: values.reason,
      },
    };

    if (values.reason) {
      api
        .submitFlagReport(list.id, payload)
        .then(data => {
          setFlagData(data);
          setConfirmationModal(false);
        })
        .catch(err => {
          console.log(err);
          setSubmitting(false);
        });
    } else {
      setSubmitting(false);
    }
  };

  return (
    <div
      key={list.id}
      className={
        openAnnouncement ? styles.greyProposalItem : styles.proposalItem
      }
    >
      <div className={`${styles.proposalWrapper} ${styles.proposalItemStart}`}>
        <div className={styles.proposalActions}>
          <div className={styles.postHeading}>
            <p>{_.startCase(list.user_name)}</p>
            <p>
              <GoPrimitiveDot />
            </p>
            <p> {list.created_at}</p>
          </div>
          <div className={styles.dropDown}>
            <IoFlagOutline
              onClick={() => setOpenAnnouncement(!openAnnouncement)}
              className={
                openAnnouncement ? styles.redDropIcon : styles.dropIcon
              }
            />
            {openAnnouncement &&
              (flagData?.flagged === true || list?.flagged === true ? (
                <div className={styles.flaggeddropDownBox}>
                  Ilmoitit tästä viestistä
                </div>
              ) : list.flagged === false ? (
                <div
                  onClick={() => setConfirmationModal(true)}
                  className={styles.dropDownBox}
                >
                  Ilmoita asiattomasta ehdotuksesta
                </div>
              ) : null)}
          </div>
        </div>

        <ProposalPostModal
          confirmationModal={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          setOpenAnnouncement={setOpenAnnouncement}
          data={data}
          list={list.id}
          handleReportSubmission={handleReportSubmission}
        />

        <div className={styles.actionContainer}>
          <h1 className={styles.proposalTitle}> {list.title}</h1>
        </div>
        <div className={styles.tag}>
          <Tag value={list.category} lightblue />
        </div>
        <RichText content={list.content} />

        <UpvoteContainer
          data={list}
          proposalType="proposal"
        />
      </div>

      <div className={styles.proposalWrapper}>
        {response ? (
          <Fragment>
            <div className={styles.postResponseHeading}>
              <p>Talotoimikunnan vastaus</p>
              <p>
                <GoPrimitiveDot />
              </p>
              <p> {response?.created_at}</p>
            </div>
            <div className={styles.postContent}>
              <RichText content={response?.content} />
            </div>

            <UpvoteContainer
              data={list.response}
              proposalType="response"
            />
          </Fragment>
        ) : (
          <span>Vastaus on vielä kesken</span>
        )}
      </div>
    </div>
  );
};

export default ProposalPost;
