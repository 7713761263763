import React, { useState, useContext, useRef } from "react";
import Select from "react-select";
import PageContainer from "../PageContainer/PageContainer";
import PageHeadings from "../PageHeadings/PageHeadings";
import { Formik, Form } from "formik";
import { Persist } from "formik-persist";
import { TextInputBox, Button, InputError } from "../Inputs/Inputs";
import { FiAlertCircle } from "react-icons/fi";

import { useHistory } from "react-router-dom";

import styles from "./MyProposalForm.module.scss";
import PageAlignment from "../common/PageAlignment/PageAlignment";
import SecurityContext from "../Context/SecurityContext";
import "trix/dist/trix.css";
import { BiArrowBack } from "react-icons/bi";
import ComboBox from "../common/ComboBox/ComboBox";
import ProposalModal from "./ProposalFormModal";

// This has to be imported or Trix editor won't work
import Trix from "trix"
import { TrixEditor } from "react-trix";

const MyProposalForm = ({ proposalData }) => {
  const { api } = useContext(SecurityContext);
  const [richTextContent, setRichTextContent] = useState(null);
  const [trixEditorError, setTrixEditorError] = useState("");
  const [selectFieldError, setSelectFieldError] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const { data } = proposalData;

  const formRef = useRef();

  let history = useHistory();

  //error display for touched trix editor field
  document.addEventListener("trix-blur", event => {
    setTrixEditorError("Ehdotuksen kuvaus ei voi olla tyhjä");
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#00d7a7" : "#00000",
      borderStyle: "solid",
      borderWidth: "1px",
      "&:hover": {
        borderColor: "#00000",
      },
      margin: "12px 0",
    }),
  };

  const proposalOptions = data?.user_proposal_categories.map(category => {
    return {
      label: category.name,
      value: category.value,
    };
  });

  const handleSubmitProposal = () => {
    const proposalData = {
      user_proposal: {
        title: formRef.current.values.title,
        content: richTextContent,
        user_name: formRef.current.values.user_name,
        category: formRef.current.values.category,
      },
    };

    api
      .submitProposalForm(proposalData)
      .then(response => {
        if (response) {
          history.push({
            pathname: "/community",
            state: response,
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const elementEditor = document.querySelector("trix-editor");
  const handleChange = html => {
    let maximumLength = 2023;
    const limitTextCharacter = html.substring(0, maximumLength);
    if (html.length > maximumLength) {
      const currentSelectedRange = elementEditor.editor.getSelectedRange();
      //prevent user from adding text after reaching maximum length
      elementEditor.editor.setSelectedRange([maximumLength, html.length]);
      elementEditor.editor.deleteInDirection("backward");
      //restore state
      elementEditor.editor.setSelectedRange(currentSelectedRange);
    }
    setRichTextContent(limitTextCharacter);
  };

  return (
    <PageContainer>
      <PageAlignment>
        <BiArrowBack
          style={{ fontSize: "1.5rem", cursor: "pointer" }}
          onClick={() => history.goBack()}
        />
        <PageHeadings headingTitle="Ehdota talotoimikunnalle" />
        <p className={styles.textWidth}>
          Ehdotuksellasi voit tuoda toiveesi talotoimikuntasi harkintaan.
          Ehdotuksesi julkaistaan nimelläsi, ja se näkyy muille talosi
          asukkaille. Voit halutessasi liittää ehdotukseesi kuvan.
        </p>
        <p>
          Muut asukkaat voivat kannattaa ehdotustasi, ja voit saada ehdotukseesi
          vastauksen talotoimikunnalta.
        </p>
        <div className={styles.alertBox}>
          <FiAlertCircle className={styles.alertIcon} />
          <p>
            Mikäli asiasi koskee vikaa asunnossasi, teethän siitä mieluummin
            vikailmoituksen.
          </p>
        </div>
        <ComboBox
          comboDescription="Esimerkkejä hyvistä ehdotuksista"
          suggestions
        />
        <Formik
          initialValues={{
            title: "",
            content: "",
            user_name:
              data?.user_name === null || data?.user_name === undefined
                ? ""
                : data?.user_name,
            category: "",
          }}
          innerRef={formRef}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => {
            const noErrors =
              !errors.values?.title &&
              !errors.values?.content &&
              !errors.values?.category &&
              !errors.values?.user_name;

            return (
              <Form>
                {!values.user_name && touched.user_name ? (
                  <InputError>Sinun nimesi ei voi olla tyhjä</InputError>
                ) : null}
                <TextInputBox
                  formik
                  required
                  placeholder="Sinun nimesi"
                  name="user_name"
                  readOnly={data?.user_name !== null}
                />
                {!values.title && touched.title ? (
                  <InputError>Otsikko ei voi olla tyhjä</InputError>
                ) : null}
                <TextInputBox
                  formik
                  required
                  placeholder="Otsikko"
                  name="title"
                />
                {!values.category ? (
                  <InputError>{selectFieldError}</InputError>
                ) : null}
                <Select
                  placeholder="Kategoria"
                  options={proposalOptions || []}
                  className="basic-single"
                  styles={customStyles}
                  name="category"
                  onBlur={() =>
                    setSelectFieldError("Kategoria ei voi olla tyhjä")
                  }
                  onChange={selectedCategory => {
                    setFieldValue("category", selectedCategory.value);
                  }}
                />
                {!richTextContent ? (
                  <InputError>{trixEditorError}</InputError>
                ) : null}
                <div className={styles.editorContainer}>
                  <TrixEditor
                    placeholder="Ehdotuksen kuvaus"
                    onChange={handleChange}
                  />
                  <i>Enintään 2000 merkkiä</i>
                </div>
                <Button
                  onClick={() => setConfirmationModal(true)}
                  type="button"
                  tealButton
                  disabled={
                    isSubmitting ||
                    !values.title ||
                    !richTextContent ||
                    !values.category ||
                    !values.user_name ||
                    !noErrors
                  }
                >
                  Luo uusi ehdotus
                </Button>
                <Persist name="proposal-form" />
              </Form>
            );
          }}
        </Formik>
        <ProposalModal
          confirmationModal={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          handleSubmitProposal={handleSubmitProposal}
        />
      </PageAlignment>
    </PageContainer>
  );
};

export default MyProposalForm;
