import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Collapse } from "react-collapse";
import styles from "./ComboBox.module.scss";
import classNames from "classnames";
// import Tag from "../../Tag/Tag";
import { RichText } from "../RichText/RichText";

const ComboBox = ({
  comboDescription,
  comboContentTitle,
  comboContentDate,
  comboContentInfo,
  comboTag,
  suggestions,
  scheduledEvents,
  taglessContent,
}) => {
  const [open, setOpen] = useState(false);

  const challengeClassNames = classNames(styles.challenge, {
    [styles.open]: open,
  });

  const openItem = () => {
    setOpen(!open);
  };
  const suggestionList = [
    {
      id: "1",
      suggestion: "Suggestion 1",
    },
    {
      id: "2",
      suggestion: "Suggestion 2",
    },
  ];

  return (
    <div className={challengeClassNames}>
      <div className={styles.top}>
        <button
          className={`${styles.formButtons} ${styles.clickable}`}
          onClick={() => openItem()}
        >
          <div>{comboDescription}</div>
          <div className={styles.itemContainer}>
            {/* {comboTag ? open &&
              <Tag value={comboTag} outlineTeal />
              : null} */}
            <div className={styles.openIndicator}>
              <RiArrowDropDownLine className={styles.chevronDrop} />
            </div>
          </div>
        </button>
        <Collapse isOpened={open}>
          {/* {comboTag ? ( */}
          <div className={styles.collapseContent}>
            <h1 className={styles.heading}>{comboContentTitle}</h1>
            {/* <Tag value={comboContentDate} outlineTeal /> */}
            <RichText content={comboContentInfo} />
          </div>
          {/* ) : null} */}

          {suggestions ? (
            <ul
              className={`${styles.collapseContent} ${styles.suggestionList}`}
            >
              {suggestionList.map(list => (
                <li key={list.id}>{list.suggestion}</li>
              ))}
            </ul>
          ) : scheduledEvents ? (
            <ul
              className={`${styles.collapseContent} ${styles.suggestionList}`}
            >
              {scheduledEvents}
            </ul>
          ) : (
            <RichText content={taglessContent} />
          )}
        </Collapse>
      </div>
    </div>
  );
};

export default ComboBox;
