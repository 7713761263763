import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import PageContainer from "../PageContainer/PageContainer";
import { BiArrowBack } from "react-icons/bi";
import { RichText } from "../common/RichText/RichText";
import Tag from "../Tag/Tag";
import _ from "lodash";
import SecurityContext from "../Context/SecurityContext";

import styles from "./AnnouncementLinkPage.module.scss";

const AnnouncementLinkPage = () => {
  const [copyAnnouncementData, setCopyAnnouncementData] = useState([]);
  const params = useParams();
  let history = useHistory();
  const { api } = useContext(SecurityContext);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      api
        .getAnnouncementLink(params.id)
        .then(response => {
          if (response && response.data) {
            setCopyAnnouncementData(response.data);
          }
        })
        .catch(err => {
          console.log(err);
          setCopyAnnouncementData("Tietojen haku epäonnistui");
        });
    }

    return () => (ignore = true);
  }, [api, params.id]);

  const { tags } = copyAnnouncementData;

  return (
    <PageContainer>
      <div className={styles.pageHeader}>
        <button>
          <BiArrowBack
            style={{ fontSize: "1.5rem", cursor: "pointer" }}
            onClick={() => history.push('/')}
          />
        </button>
      </div>

      <div className={styles.pageWrapper}>
        <div>
          <h1 className={styles.title}>{copyAnnouncementData.title}</h1>

          <div className={styles.tagsContainer}>
            <div>
              {tags && tags[0] === "Heka" ? (
                <Tag value="Heka" whiteblue />
              ) : tags && tags[0] === "Ekoekspertti" ? (
                <Tag value="Ekoekspertti" blackteal />
              ) : tags && tags[0] === "Talotoimikunta" ? (
                <Tag value="Talotoimikunta" orange />
              ) : null}
            </div>
            <div>
              <p>{_.capitalize(copyAnnouncementData.sent_at)}</p>
            </div>
          </div>

          <div>
            {copyAnnouncementData ? (
              <div>
                <RichText content={copyAnnouncementData.content} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default AnnouncementLinkPage;
