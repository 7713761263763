import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { FiThumbsUp, FiStar } from "react-icons/fi";
import { CgMoreVerticalO } from "react-icons/cg";

import Tag from "../Tag/Tag";
import Spinner from "../Spinner/Spinner";
import SecurityContext from "../Context/SecurityContext";
import { BsCheck } from "react-icons/bs";

import { filterWithSearchQuery } from "./utils";

import styles from "./Feed.module.scss";

const Feed = ({ feed, loading, searchQuery }) => {
  let filteredFeed = filterWithSearchQuery(feed?.data?.feed_items, searchQuery);

  filteredFeed = filteredFeed?.map((item, index) => {
    return <Item item={item} index={index} key={index} loading={loading} />;
  });

  return (
    <div className={styles.feed}>
      {loading ? (
        <div className={styles.spinnerContainer}>
          <Spinner className={styles.spinner}>Ladataan</Spinner>
        </div>
      ) : feed.error ? (
        <div className={styles.textCenter}>{feed.error}</div>
      ) : (
        <>
          <div className={styles.timeline}>
            <div className={styles.timelineGradient} />
          </div>
          <div className={styles.items}>{filteredFeed}</div>
        </>
      )}
    </div>
  );
};

const Item = ({ item, loading }) => {
  const { api } = useContext(SecurityContext);
  const [totalUpvotes, setTotalUpvotes] = useState(null);
  const [openAnnouncement, setOpenAnnouncement] = useState(false);
  const [copiedStatus, setCopiedStatus] = useState(false);
  const [copiedLinkStatus, setCopiedLinkStatus] = useState(false);

  const date = getDate(item.sent_at);

  const handleLikes = upvotedItem => {
    api
      .upVoteAnnouncement(upvotedItem.id)
      .then(response => {
        setTotalUpvotes(response);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const markAsRead = itemId => {
    api.markFeedItemAsRead(itemId)
  }

  function convertToPlain(html) {
    // Create a new div element
    const tempDivElement = document.createElement("div");
    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;
    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  const handleCopyText = item => {
    const convertedRichText = convertToPlain(item.content);
    navigator.clipboard.writeText(convertedRichText).then(
      () => {
        setCopiedStatus(true);
      },
      () => {
        setCopiedStatus(false);
      }
    );
  };

  const handleCopyTextLink = item => {
    navigator.clipboard
      .writeText(`${window.location}announcement-link/${item}`)
      .then(
        () => {
          setCopiedLinkStatus(true);
        },
        () => {
          setCopiedLinkStatus(false);
        }
      );
  };

  return (
    <div
      className={
        item.important === true ? styles.importantItem : styles.nonImportantItem
      }
    >
      <div className={styles.actionContainer}>
        <Link
          to={{
            pathname: `/announcement-link/${item.id}`,
          }}
          onClick={() => markAsRead(item.id)}
        >
          <h1 className={styles.title}>{item.title}</h1>
        </Link>
        <div className={styles.dropDown}>
          <div className={styles.actionIcon}>
            <div
              className={`${styles.iconContainer} ${totalUpvotes?.upvoted === true || item.upvoted === true
                ? styles.blueColor
                : styles.defaultColor
                }`}
            >
              <FiThumbsUp
                onClick={() => handleLikes(item)}
                className={`${totalUpvotes?.upvoted === true
                  ? styles.thumbIconAnimate
                  : null
                  }`}
              />
              <p>
                {totalUpvotes?.upvoted === true
                  ? totalUpvotes?.total_upvotes
                  : totalUpvotes?.upvoted === false
                    ? totalUpvotes?.total_upvotes
                    : item.total_upvotes}
              </p>
            </div>

            <div>
              <CgMoreVerticalO
                onClick={() => setOpenAnnouncement(!openAnnouncement)}
                className={`${styles.dropIcon} ${openAnnouncement ? styles.blueDropIcon : styles.defaultColor
                  }`}
              />
            </div>

            {openAnnouncement && (
              <div className={styles.dropDownBox}>
                <p
                  onClick={() => handleCopyText(item)}
                  className={
                    copiedStatus === true
                      ? styles.copiedColor
                      : styles.defaultColor
                  }
                >
                  Kopioi teksti
                </p>
                <p
                  onClick={() => handleCopyTextLink(item.id)}
                  className={
                    copiedLinkStatus === true
                      ? styles.copiedColor
                      : styles.defaultColor
                  }
                >
                  Kopioi linkki tiedotteeseen
                </p>
              </div>
            )}

          </div>
        </div>
      </div>

      <Link
        to={{
          pathname: `/announcement-link/${item.id}`,
        }}
        onClick={() => markAsRead(item.id)}
      >
        <div className={styles.top}>
          <div className={styles.infoContainer}>
            <div className={styles.tag}>
              {item.tags[0] === "Heka" ? (
                <Tag value="Heka" whiteblue />
              ) : item.tags[0] === "Ekoekspertti" ? (
                <Tag value="Ekoekspertti" blackteal />
              ) : item.tags[0] === "Talotoimikunta" ? (
                <Tag value="Talotoimikunta" orange />
              ) : null}
            </div>

            <div>
              {item.important === true && (
                <FiStar className={styles.importantContainer} />
              )}
            </div>

            <div className={styles.date}>{date}</div>
          </div>

          {item.read && (
            <div className={styles.checkMark}>
              <BsCheck />
            </div>
          )}

        </div>
      </Link>
    </div>
  );
};

export default Feed;

function getDate(sent_at) {
  if (!sent_at) return "-";

  const capitalized = sent_at[0].toUpperCase() + sent_at.slice(1);
  const array = capitalized.split(".");
  const month = getMonth(array[1]);
  return `${array[0]}. ${month} ${array[2]}`;
}

function getMonth(string) {
  const num = parseInt(string);
  let month;
  switch (num) {
    case 1:
      month = "tammikuuta";
      break;
    case 2:
      month = "helmikuuta";
      break;
    case 3:
      month = "maaliskuuta";
      break;
    case 4:
      month = "huhtikuuta";
      break;
    case 5:
      month = "toukokuuta";
      break;
    case 6:
      month = "kesäkuuta";
      break;
    case 7:
      month = "heinäkuuta";
      break;
    case 8:
      month = "elokuuta";
      break;
    case 9:
      month = "syyskuuta";
      break;
    case 10:
      month = "lokakuuta";
      break;
    case 11:
      month = "marraskuuta";
      break;
    case 12:
      month = "joulukuuta";
      break;
    default:
      month = string;
  }
  return month;
}
